import { Box } from "@mui/material";
import { sessionLogo } from "./styles";
import energeiaLogo from "images/energeiaLogo.png";
const SessionLogo = ({ width = "280" }) => {
  return (
    <Box sx={sessionLogo()}>
      <Box>
        <a href="http://energeia-usa.com" target="_blank" rel="noreferrer">
          <img src={energeiaLogo} alt="Energeia Logo" width={width} />
        </a>
      </Box>
    </Box>
  );
};

export default SessionLogo;
