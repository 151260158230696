import { API } from "aws-amplify";

export const GET_ASSET_HIERARCHY_LIST_REQUEST =
  "@@assetHierarchy/GET_ASSET_HIERARCHY_LIST_REQUEST";
export const GET_ASSET_HIERARCHY_LIST_SUCCESS =
  "@@assetHierarchy/GET_ASSET_HIERARCHY_LIST_SUCCESS";
export const GET_ASSET_HIERARCHY_LIST_FAILURE =
  "@@assetHierarchy/GET_ASSET_HIERARCHY_LIST_FAILURE";

export function getAssetHierarchyList(
  id = "",
  organisation,
  sample_table = true
) {
  return async (dispatch) => {
    if (!(id && organisation)) return;
    dispatch({
      type: GET_ASSET_HIERARCHY_LIST_REQUEST,
    });
    try {
      let url = "/showdata";
      if (id && organisation) {
        url = `${url}?project_id=${id}&table_name=in_asset_classes&organisation=${organisation}&sample_table=${sample_table}`;
      }

      const response = await API.get("usimAPI", url);
      dispatch({
        type: GET_ASSET_HIERARCHY_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSET_HIERARCHY_LIST_FAILURE,
        payload: error?.response?.data,
      });
    }
  };
}
