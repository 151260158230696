import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ProgressBar } from "components";
import { routes } from "constants/projectRoutes";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckCircleOutline,
  LockOutlined,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getProjectCompletion } from "utils/project";

const styles = () => {
  return {
    height: "100%",
    minWidth: "264px",
    padding: "16px 12px",
    backgroundColor: "white",
    borderRadius: "4px",
    "& .MuiListItemText-root": {
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#F6F7FC",
      color: "#2B7FE0",
      borderRadius: "4px",
    },
    "& .active-menu": {
      backgroundColor: "#F6F7FC",
      color: "#2B7FE0",
      borderRadius: "4px",
      fontWeight: 500,
    },
    "& .MuiListItemIcon-root": {
      marginRight: "4px",
    },
  };
};

const ProjectSidebar = ({ id }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  const project = useSelector((state) => state.projects?.details);

  const PAGE_LOCKED_MESSAGE = "This page will unlock once all data has been validated within the data section.";

  useEffect(() => {
    if (id && location.pathname) {
      setSelectedMenu("");
      setSelectedSubMenu("");
      const list = location.pathname.split(id);
      if (list.length > 1) {
        const tokens = list[1].slice(1).split("/");
        tokens.forEach((it, i) => {
          if (i === 0) {
            setSelectedMenu(it.replace("/", ""));
          } else if (i === 1) {
            setSelectedSubMenu(it.replace("/", ""));
          }
        });
      }
    }
  }, [location, id]);

  const onClickMenu = (menu) => {
    setSelectedMenu(menu.route);
    navigate(`/project/upload-data/${id}/${menu.route}`);
  };

  const onClickSubMenu = (menu, subMenu) => {
    if (unavailable(subMenu)) {
      return;
    }
    setSelectedSubMenu(subMenu.route);
    navigate(`/project/upload-data/${id}/${menu.route}/${subMenu.route}`);
  };

  const renderCompleted = (menu) => {
    if (project[menu.stepValidationKey] !== "True") return;
    return (
      <ListItemIcon>
        <CheckCircleOutline
          sx={{
            color: "#229A16 !important",
          }}
        />
      </ListItemIcon>
    );
  };

  const renderSubMenuCompleted = (subMenu) => {
    if (
      !project[subMenu.inputKey] ||
      (project[subMenu.inputKey] &&
        project[subMenu.inputKey] === "Error in Last Upload")
    ) {
      if (project[subMenu.stepValidationKey] === "True") {
        return (
          <ListItemIcon>
            <CheckCircleOutline
              sx={{
                marginRight: "4px",
                color: "#229A16 !important",
              }}
            />
          </ListItemIcon>
        );
      } else {
        return;
      }
    }

    return (
      <ListItemIcon>
        <CheckCircleOutline
          sx={{
            color: "#2B7FE0 !important",
          }}
        />
      </ListItemIcon>
    );
  };

  const unavailable = (subMenu) => {
    if (
      subMenu?.validateRequired &&
      project[subMenu?.validateRequired] !== "True"
    ) {
      return true;
    } else if (subMenu?.inputRequired && subMenu?.inputRequired.length > 0) {
      let count = 0;
      subMenu.inputRequired.forEach((it) => {
        count = project[it] ? count + 1 : count;
      });

      return subMenu?.inputRequired.length !== count;
    }

    return false;
  };

  return (
    <Box sx={styles}>
      <Typography mb={1} variant="body2" fontWeight={500}>
        UPLOAD DATA LIST
      </Typography>

      <Typography variant="body2">
        You’ve completed <b>{getProjectCompletion(project)}%</b> of the setup
      </Typography>
      <ProgressBar value={getProjectCompletion(project)} />

      <Box mt={2}>
        <List>
          {routes.map((menu, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => onClickMenu(menu)}
                sx={{
                  minHeight: 40,
                  px: 1.5,
                  mb: "1px",
                }}
                className={selectedMenu === menu.route ? "active-menu" : ""}
              >
                {renderCompleted(menu)}
                <ListItemText primary={menu.label} />

                {!menu.standalone && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      mr: 0,
                      color: "#A5ABB5",
                    }}
                  >
                    {selectedMenu === menu.route ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </ListItemIcon>
                )}
              </ListItemButton>
              {selectedMenu === menu.route && menu.children.length > 0 && (
                <Box ml={2}>
                  <List>
                    {menu.children.map((subMenu, i) => (
                      <Tooltip
                        key={i}
                        title={unavailable(subMenu) ? PAGE_LOCKED_MESSAGE : ""}
                        placement="top-end"
                        followCursor
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            onClick={() => onClickSubMenu(menu, subMenu)}
                            sx={{
                              minHeight: 40,
                              px: 1.5,
                              mb: "1px",
                              pointerEvents: unavailable(subMenu)
                                ? "none"
                                : undefined,
                            }}
                            className={
                              selectedSubMenu === subMenu.route
                                ? "active-menu"
                                : ""
                            }
                          >
                            {renderSubMenuCompleted(subMenu)}
                            <ListItemText primary={subMenu.label} />
                            {unavailable(subMenu) && (
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  justifyContent: "center",
                                  mr: 0,
                                  color: "#A5ABB5",
                                }}
                              >
                                <LockOutlined />
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    ))}
                  </List>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ProjectSidebar;
