import { Box, Typography } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
// import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid-pro";

const CustomFooter = ({ footerMessage }) => {
  return (
    <GridFooterContainer>
      <Box p={1}>
        <Typography fontWeight={500} fontSize="10px">
          {footerMessage}
        </Typography>
      </Box>
      <GridFooter />
    </GridFooterContainer>
  );
};

const formatNumber = (value) => {
  if (value === null || value === undefined) return value;

  // Handle percentage values expressed as strings with a '%' at the end
  if (typeof value === "string" && value.endsWith("%")) {
    const number = parseFloat(value.slice(0, -1));
    if (isNaN(number)) return value; // Return original string if not a number
    return `${parseFloat(number.toFixed(2)).toLocaleString()}%`;
  }

  // Handle numerical values with comma formatting
  if (typeof value === "number") {
    if (Number.isInteger(value)) {
      return value.toLocaleString(); // Adds commas for integer values
    }
    return parseFloat(value.toFixed(2)).toLocaleString(); // Converts back to number then adds commas
  }

  // Handle boolean values
  if (typeof value === "boolean") {
    return value ? "True" : "False";
  }

  // Return the raw value if it's a string or any other format not handled above
  return value;
};

const styles = () => {
  return {
    width: "100%",
    "& .MuiDataGrid-root": {
      fontSize: "13px",
      borderRadius: 0,
      marginBottom: "12px",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E9ECF5",
      borderBottom: "1px solid #D6DDEA",
      borderRadius: 0,
      textAlign: "center", // Center align the text in the column headers
      "& .MuiDataGrid-withBorderColor": {
        backgroundColor: "#E9ECF5",
        border: "1px solid #D6DDEA",
        borderRadius: 0,
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold", // Optional: bold the header titles
      flex: 1,
      display: "flex",
      justifyContent: "center",
      headerAlign: "center",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "unset !important",
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      outline: "unset !important",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "unset !important",
    },
    "& .MuiDataGrid-row": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "white",
      minHeight: "40px",
      "& .MuiDataGrid-selectedRowCount": {
        visibility: "hidden",
      },
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "40px",
      "& p": {
        marginTop: "8px",
        marginBottom: "8px",
      },
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowY: "scroll !important",
      maxHeight: "calc(100vh - 360px)",
    },
  };
};

const DataTable = ({
  rows = [],
  columns = [],
  loading = false,
  onRowClick = () => {},
  hideFooter = false,
  enableFormatting = true,
  footerMessage = "",
}) => {
  const formattedColumns = columns.map((col) => {
    if (enableFormatting) {
      return {
        ...col,
        renderCell: (params) => formatNumber(params.value),
      };
    }
    return col;
  });

  return (
    <Box sx={styles}>
      {/* <DataGrid
        rows={rows}
        columns={formattedColumns}
        autoHeight={true}
        loading={loading}
        rowHeight={40}
        columnHeaderHeight={40}
        disableColumnSelector={true}
        disableColumnFilter={true}
        showColumnVerticalBorder={true}
        onRowClick={onRowClick}
        hideFooter={hideFooter}
        slots={{
          footer: CustomFooter,
        }}
        slotProps={{
          footer: { footerMessage },
        }}
      /> */}
      <DataGridPro
        rows={rows}
        columns={formattedColumns}
        autoHeight={true}
        loading={loading}
        rowHeight={40}
        columnHeaderHeight={40}
        disableColumnSelector={true}
        disableColumnFilter={true}
        showColumnVerticalBorder={true}
        onRowClick={onRowClick}
        hideFooter={hideFooter}
        slots={{
          footer: CustomFooter,
        }}
        slotProps={{
          footer: { footerMessage },
        }}
      />
    </Box>
  );
};

export default DataTable;
